import React from "react";
import "./secondary.css";
const Footer = () => {
  return (
    <div className="">
      <div className="flex justify-center items-center pt-4 md:pb-[0px] sm:pb-4">
        <hr className="break-line" />
      </div>
      <div className="flex align-middle justify-center text-center">
        <p className="text-gray-light p-[3px] text-justify text-sm mx-[7vw] mb-[2px] md:mx-[9.5vw] xl:mx-[2vw]">
          Imaizumi's House is a place for girls to gather volume 6; Imaizumin’s
          Brings All Gyaru to House Volume 6; Imaizumi volume 6; Imaizumi
          English official volume 6; Imaizumi English doujin volume 6; Imaizumi
          English hentai volume 6; Imaizumi hentai 6;
        </p>
      </div>
    </div>
  );
};
export default Footer;
