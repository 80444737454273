import React, { useState, useEffect } from "react";
import Navigator from "./NNavigator-image";
import { FaArrowUp } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
const base_uri = "";
const Chapter_render = ({ type }) => {
  const navigate = useNavigate();
  const [content, getContent] = useState({});
  const { id } = useParams();
  let uri = ``; //http://localhost:3001
  function fetch_keys(chaps) {
    var keys = Object.keys(chaps);
    return keys.sort();
  }
  const scrollToTop = () => {
    const targetImage = document.getElementById(0);
    if (targetImage) {
      targetImage.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    const fetchContent = async () => {
      getContent({}); //Remove all images from webpage.
      let uri = base_uri + `/Chapter/Images/${id}`; //http://localhost:3001
      let res = await fetch(uri + `/image.json`);
      if (!res.ok) {
        getContent("Failed to Load");
      } else {
        const newData = await res.json();
        getContent(() => newData);
      }
    };

    fetchContent();
  }, [id]);
  return (
    <div className="bg-[#262626] h-[100%] w-[100%] md:h-[100%] md:w-[100%]">
      {/* if there is content which doesn't cover 100% of the Window Height you need to use h-[100vh] else use 100% to cover full page*/}
      <Navbar />
      <div className="grid grid-flow-col">
        <div className="flex flex-row text-[0.8rem] underline underline-offset-2 bg-gray-light mb-1 md:mb-1 ">
          <p
            onClick={() => {
              navigate("/");
            }}
            className="hover:text-purple ml-3 mx-[10vw] mb-[2px] md:mx-[14vw]"
          >
            Imaizumin’s House is a Place for Gals to Gather/Chapter/{id}
          </p>
        </div>
      </div>
      {/*md:mx-[8vw]  */}
      <div className="flex justify-center mb-2 mx-4 md:mx-[14vw] lg:mx-[10vw] xl:mx-[14vw]">
        <Navigator current={id} />
      </div>
      <div className="flex flex-col items-center bg-[#262626] mx-[6vw] md:mx-[8vw] lg:mx-[10vw] xl:mx-[14vw] gap-1">
        {fetch_keys(content).map((images, index) => (
          <React.Fragment key={index}>
            <img
              className="max-w-full h-auto"
              src={base_uri + uri + content[images]["path"].slice(1)} //http://localhost:3001 remove the slice(1)
              alt={content[images]["alt_name"]}
              id={index}
            />
            <br />
          </React.Fragment>
        ))}
      </div>
      <div className="flex justify-center mb-2 mx-4 md:mx-[14vw] lg:mx-[10vw] xl:mx-[14vw]">
        <Navigator current={id} />
      </div>
      <div className="goTop size-5" onClick={scrollToTop}>
        <FaArrowUp className="size-5" style={{ color: "#7e5bef" }} />
      </div>
      <Footer />
    </div>
  );
};
export default Chapter_render;
