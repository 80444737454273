import React from "react";
import QRCode from "../Images/ltc.jpeg";

const LTCQRCode = () => {
  const LTCAddress = "ltc1q0te4a7m2qvk00hzh2j9xlfx4qr22k4fvx4gwze";

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <h1>QR Code and LTC Address</h1>
      <p>Scan the QR code or copy the LTC address:</p>
      <h1>QR Code</h1>
      <img
        src={QRCode}
        alt="QR Code"
        style={{
          paddingLeft: "10vw",
          paddingRight: "10vw",
          alignContent: "center",
          height: "50%",
        }}
      />
      <p>
        LTC Address: <code>{LTCAddress}</code>
      </p>
    </div>
  );
};

export default LTCQRCode;
