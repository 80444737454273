import "./App.css";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Home from "./Components/Home";
import Chapter from "./Components/Chapter-render-image";
import Doge from "./Components/doge";
import BCH from "./Components/bch";
import Ltc from "./Components/ltc";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/Chapter/:id" element={<Chapter />} />
      <Route path="*" element={<HandleRedirect />} />
      <Route path="/doge" element={<Doge />} />
      <Route path="/bch" element={<BCH />} />
      <Route path="/ltc" element={<Ltc />} />
    </Routes>
  );
}

function HandleRedirect() {
  const location = useLocation();
  const path = location.pathname;

  // Debugging: Log the path
  console.log("Current Path:", path);

  // Check if the path matches the pattern "/chapter-<id>"
  const match = path.match(/\/chapter-(\d+-?\d*)/);

  if (match) {
    // Extract the chapter ID, replacing "-" with "."
    const chapterId = match[1].replace("-", ".");
    console.log("Redirecting to Chapter ID:", chapterId);

    // Redirect to the new format
    return <Navigate to={`/Chapter/${chapterId}`} replace />;
  }

  // If no match, return null or handle other cases
  return <Navigate to="/" replace />;
}
export default App;
