import React from "react";
import QRCode from "../Images/doge.jpeg";

const DogecoinQRCode = () => {
  const dogeCoinAddress = "DDQ2tAW2HehYwtKc9ur37PEWSjugVDgUcP";

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <h1>QR Code and Dogecoin Address</h1>
      <p>Scan the QR code or copy the Dogecoin address:</p>
      <h1>QR Code</h1>
      <img
        src={QRCode}
        alt="QR Code"
        style={{
          paddingLeft: "10vw",
          paddingRight: "10vw",
          alignContent: "center",
          height: "50%",
        }}
      />
      <p>
        Dogecoin Address: <code>{dogeCoinAddress}</code>
      </p>
    </div>
  );
};

export default DogecoinQRCode;
