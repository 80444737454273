import React from "react";
import QRCode from "../Images/bch.jpeg";

const BCHQRCode = () => {
  const BCHAddress = "qp2fw955eypvpghq4jufh6w7lg09d3x0uckty2t8wq";

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <h1>QR Code and Bitcoin Cash Address</h1>
      <p>Scan the QR code or copy the Bitcoin Cash address:</p>
      <h1>QR Code</h1>
      <img
        src={QRCode}
        alt="QR Code"
        style={{
          paddingLeft: "10vw",
          paddingRight: "10vw",
          alignContent: "center",
          height: "50%",
        }}
      />
      <p>
        Bitcoin Cash Address: <code>{BCHAddress}</code>
      </p>
    </div>
  );
};

export default BCHQRCode;
